import handleOpenApp from 'utils/handleOpenApp';
import mainImg from 'assets/images/land/hero.jpg';

const HeroSection = () => (
  <div className="relative w-full h-[calc(100vh-5rem)] flex items-center justify-center" style={{
    backgroundImage: `url(${mainImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}>
    <div className="relative z-10 flex justify-center">
      <div className='relative z-10 text-center text-white w-[90%]'>
        <h1 className="text-5xl md:text-6xl font-extrabold mb-6 drop-shadow-lg">
          Ride Sharing Made Easy for Every Journey
        </h1>
        <h2 className="text-2xl md:text-3xl mb-8">
          Find travel companions for road trips, daily commutes, carpooling, taxi sharing and more. Start your eco-friendly, affordable journey today.
        </h2>
        <div onClick={handleOpenApp}>
          <button className="btn btn-warning btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
            Join The Ride Share Community!
          </button>
        </div>
      </div>
      <div className="absolute inset-0 bg-blue-900 opacity-50 blur-2xl" />
    </div>
    <div className="absolute inset-0 bg-blue-900 opacity-60" />
  </div>
);

export default HeroSection;
