import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-blue-600 text-white p-6 w-full shadow mt-auto">
      {/* Footer Content */}
      <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">

        {/* Links Section */}
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6 text-center">
          <Link to="/terms" className="hover:text-gray-300">
            Terms of Use
          </Link>
          <Link to="/privacy-policy" className="hover:text-gray-300">
            Privacy Policy
          </Link>
          <Link to="/contact" className="hover:text-gray-300">
            Contact Us
          </Link>
        </div>

        {/* Social Icons Section */}
        <div className="flex space-x-6">
          <a href="https://web.facebook.com/RideShareRide" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
            <FaFacebook size={28} />
          </a>
          <a href="https://www.instagram.com/ride.share.app" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
            <FaInstagram size={28} />
          </a>
          {/* <a href="https://x.com/RideShareRide" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
            <FaTwitter size={28} />
          </a> */}
        </div>

        {/* Brand Section */}
        <div className="text-center md:text-left font-bold">
          Ride Share&trade; {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
